import React, { Component, useState, useEffect } from "react";
import { SEARCH_FILTERS } from "../constants/defaultValues";
import { useProperties } from "../hooks/use-properties";

/**
 * App Context
 * 
 * In this context we manage application information that becomes available after
 * the Authentication of the user is successful.
 */
const AppContext = React.createContext();

const AppDataProvider = (props) => {

  const properties = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [hideFooter, setHideFooter] = useState(false);

  /**
   * Returns the property in context.
   * 
   * When the user selects a property it get's remembered and is returned
   * from local storage.
   * 
   * If no property selected returns the first property returned by the API
   */
  const getProperty = () => {

    if (selectedProperty)
      return selectedProperty;

    const storedSelectedProperty = JSON.parse(localStorage.getItem("selected_property"));
    if (storedSelectedProperty)
      return storedSelectedProperty;

    return properties && properties.length > 0 && properties[0];
  }

  const getCharger = () => {
    return selectedCharger;
  }

  const getVehicle = () => {
    return selectedVehicle;
  }
  /**
   * Sets the property in context and remembers the selection in local storage.
   * This is cleared out on sign-out
   * @param {*} property 
   */
  const selectProperty = (property) => {
    setSelectedProperty(property);
    localStorage.setItem("selected_property", JSON.stringify(property));
  }

  const selectCharger = (charger) => {
    setSelectedCharger(charger);
  }

  const selectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    console.log("Vehicle selected", vehicle);
  }

  const saveSearchFilter = (page, filter) => {
    if (localStorage.getItem(SEARCH_FILTERS) != null) {
      let filters = JSON.parse(localStorage.getItem(SEARCH_FILTERS));
      filters[page] = filter;
      localStorage.setItem(SEARCH_FILTERS, JSON.stringify(filters));
    } else {
      let filters = {};
      filters[page] = filter;
      localStorage.setItem(SEARCH_FILTERS, JSON.stringify(filters));
    }
  }

  const getSearchFilter = (page, defaultFilter = {}) => {
    let filters = localStorage.getItem(SEARCH_FILTERS);
    return filters ? (JSON.parse(filters)[page] || defaultFilter) : defaultFilter;
  }

  return (
    <AppContext.Provider
      value={{ getProperty, selectProperty, getCharger, selectCharger, getVehicle, selectVehicle, saveSearchFilter, getSearchFilter, setHideFooter, hideFooter }} {...props}>
    </AppContext.Provider>
  );
}

export { AppDataProvider, AppContext }