import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import { SEARCH_FILTERS } from "../constants/defaultValues";
import { DRIVER } from "../security/roles";

const AuthContext = React.createContext();

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  profilePictureUrl: null
}

const AuthProvider = (props) => {
  const [state, setState] = useState({
    token: localStorage.getItem("token"),
    account: localStorage.getItem("account_id"),
    accountType: localStorage.getItem("account_type"),
    businessType: localStorage.getItem("business_type"),
    acl: localStorage.getItem("acl"),
    user: localStorage.getItem("user") != null ? JSON.parse(localStorage.getItem("user")) : emptyUser,
  });

  const [doTour, setDoTour] = useState(false);

  //save user info in local storage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user));
  }, [state.user]);

  const saveNewTourCompleted = (tour) => {
    // if has tour parse to list and push new tour
    if (localStorage.getItem("toursCompleted") != null) {
      let tours = JSON.parse(localStorage.getItem("toursCompleted"));
      // prevent if user clicks on help tour button to add it again
      if (tours.includes(tour)) return;
      tours.push(tour);
      localStorage.setItem("toursCompleted", JSON.stringify(tours));
    } else {
      // if doesn't have any tour, create new array with one tour
      let tours = [tour];
      localStorage.setItem("toursCompleted", JSON.stringify(tours));
    }
  }

  const getToursCompleted = () => {
    return localStorage.getItem("toursCompleted") != null ? JSON.parse(localStorage.getItem("toursCompleted")) : [];
  }

  const authFetchPure = (input, init?) => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-OK2-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }
    return fetch(input, init);
  }

  const authFetch = async (input: RequestInfo, init?: RequestInit, deleteHeader): Promise<Response> => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-OK2-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }

    if (deleteHeader) {
      delete init.headers['Content-Type'];
    }
    return fetch(input, init).then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status == 401) {
          logout();
        }

        let error = new Error('Something went wrong');
        throw { ...error, response };
      }
    }).catch((e) => {
      if (!e.response){
        let error = new Error('Something went wrong');
        let response = {status: "Timeout"};
        throw { ...error, response};
      }else{
        throw e;
      }
    })
  };

  const residentAuthFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-IPASS-RESIDENT-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }
    return fetch(input, init).then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status == 401) {
          logout();
        }
        let error = new Error('Something went wrong');
        throw { ...error, response };
      }
    });
  };


  const login = async (data) => {
    var user = { ...data.user }
    
    if (data.user.addresses && data.user.addresses.length > 0) {
      user = { ...user, address: { ...data.user.addresses[0] } }
    }
    if (data.user.companyName) {
      user = { ...user, companyName: data.user.companyName }
    }

    // if its an staff user (PM permission) set the parent account id, otherwise use the current account id
    let accountId;
    let accountType;
    let acl;
    let businessType;
    if (data.user.accounts && data.user.type != DRIVER) {
      data.user.accounts = {...data.user.accounts.filter(a => a.acl != "DEFAULT")};
      accountId = data.user.accounts[0].id;
      accountType = data.user.accounts[0].accountType;
      acl = data.user.accounts[0].acl;
      businessType = accountType == "COMPANY" ? data.user.accounts[0].businessType : "";
    } else {
      accountId = data.user.account.id;
      accountType = data.user.account.accountType;
      businessType = accountType == "COMPANY" ? data.user.account.businessType : "";
      acl = "ADMIN";
    }

    setState({
      token: data.token,
      account: accountId,
      accountType: accountType,
      businessType: businessType,
      acl: acl,
      user: user
    });

    localStorage.setItem("token", data.token); //for auth
    localStorage.setItem("account_id", accountId); //for requests
    localStorage.setItem("account_type", accountType); //for keep track of account type when changing the account (PM sub users)
    localStorage.setItem("business_type", businessType);
    localStorage.setItem("acl", acl); 
    console.log("User logged in");
  };

  // when user selects the dropdown option at the top
  const setUserAccount = (account) => {
    setState({
      ...state,
      account: account.id,
      accountType: account.accountType,
      businessType: account.businessType,
      acl: account.acl
    });
    localStorage.setItem("account_id", account.id); //for requests
    localStorage.setItem("account_type", account.accountType); //for keep track of account type when changing the account (PM sub users)
    localStorage.setItem("business_type", account.businessType);
    localStorage.setItem("acl", account.acl); //for keep track of account type when changing the account (PM sub users)
  }

  const setUserProfile = (profile) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...profile
      }
    });
  }

  // change phone number user so that it's displayed in the edit profile immediately 
  const setPhoneValidated = (phone) => {
    setState({
      ...state,
      user: {
        ...state.user,
        phone: phone
      }
    })
  }

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("account_id");
    localStorage.removeItem("user");
    localStorage.removeItem("account_type");
    localStorage.removeItem("business_type");
    localStorage.removeItem("toursCompleted");
    localStorage.removeItem(SEARCH_FILTERS);
    console.log("User logged out");
  };

  const register = (data) => {
    setState({ ...state, ...data });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, login, logout, authFetch, authFetchPure, residentAuthFetch, setUserProfile, setPhoneValidated, setDoTour, doTour, getToursCompleted, saveNewTourCompleted, setUserAccount }} {...props}>
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };