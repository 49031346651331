/* eslint-disable no-unused-vars */
import { put, takeLatest, all } from 'redux-saga/effects';
import { API_URL } from '../constants/services';
import { LOAD_MENU, MENU_LOADED, MENU_ERROR } from './actions';
// import authSagas from './auth/saga';

function* fetchMenu() {
  const accountId = localStorage.getItem("account_id");
  const token = localStorage.getItem("token");
  try {
    const menu = yield fetch(`${API_URL}/accounts/${accountId}/dashboard/menu`, { headers: { "X-BH-OK2-AUTH-TOKEN": token } })
      .then(response => response.json());
    yield put({ type: MENU_LOADED, menu: menu });
  } catch (err) {
    yield put({ type: MENU_ERROR });
  }
}

// listen when LOAD_MENU has finished
function* actionWatcher() {
  yield takeLatest(LOAD_MENU, fetchMenu)
}

export default function* rootSaga(getState) {
  yield all([actionWatcher()]);
}
