

//Stripe
export const STRIPE_PK = "pk_test_51IHdOdIRK0JICPR4F8WDBLPilaOXikMOCXO1UKRbGhp6OeeLOIzbq7lreUiuEkf3wOYnBJOhkP9PodAxMom92FZe00En49xPr2";
//export const STRIPE_PK = "pk_live_51H2bODI7Mx4gzQe3wyhQsjNuptdtOZyvfeQA1cybQkI9HyKCsA9Ms33RTou6b7DZXPOISYJoU8CTVXtQYqIj9OdG00AI3cIMaC";

//Checkpoint DVS
export const CHECKPOINT_DVS_URL = "https://dvs.checkpointid.com/api";
export const CHECKPOINT_PK = "pk_897068ef-a11b-42d9-8a03-a90bb53f41bc"; 

//BH Backend
export const BASE_URL = process.env.REACT_APP_API_BASE || "https://api.ok2charge.com";
export const BASE_WS_URL = process.env.REACT_APP_API_WS_BASE || "wss://api.ok2charge.com";
export const API_URL = BASE_URL + "/api/v1"; 
export const API_WS_URL = BASE_WS_URL + "/wsapi/v1"; 
export const API_MEDIA_URL = "https://behome247-mf.s3-us-west-2.amazonaws.com";
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const HELP_CENTER_URL = process.env.REACT_APP_HELP_CENTER_URL || "https://support.ok2charge.com";
export const OPEN_TICKET_URL = process.env.REACT_APP_OPEN_TICKET_URL || "https://share.hsforms.com/1bc4xOnPQSnudkbdp81Ox9Qcpkp2";

const DRIVER_LICENSE = 1;
const PASSPORT = 2;
const PASSPORT_CARD = 3;
const GREEN_CARD = 6;
const INTERNATIONAL = 7;

export const DocumentType = {
  DRIVER_LICENSE,
  PASSPORT,
  PASSPORT_CARD,
  GREEN_CARD,
  INTERNATIONAL
};

const OK = 0;
const FAKE = 1;
const DATA_ERROR = 2;
const SERVER_ERROR = 3;

export const DocumentStatus = {
	OK,
	FAKE,
	DATA_ERROR,
	SERVER_ERROR
};
