import { RESERVATION_CHANGE_TAB } from '../actions';

const INIT_STATE = {
  activeTab: "timeline"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESERVATION_CHANGE_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return { ...state };
  }
};
