import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import dashboard from './dashboard/reducer';
import reservations from './reservations/reducer';

const reducers = combineReducers({
  menu,
  settings,
  dashboard,
  reservations
});

export default reducers;
